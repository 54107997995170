import axios from '../utilities/axios';
import axiosIBM from '../utilities/axiosIBM';
import cookies from '../utilities/cookies';
import {dataHandler} from '../../functions/functions'


export let getAtmosphereOverview = async (levelId, type) => {
    try {
        let response = await axios.get('/levels/' + levelId + '/devices/atmosphere/stats?type=' + type);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return { error: true };
    }
}

export let getAqiDevices = async (levelId, type) => {
    try {
        let response = await axios.get('/levels/' + levelId + '/devices/stats?type=' + type);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return 'error';
    }
}

export let getAqiByLevel = async (levelId, type, filter = "hourly") => {
    try {
        let response = await axios.get('levels/' + levelId + '/atmosphere/stats?type=' + type + '&filter=' + filter);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return { error: true };
    }
}

export let getAqiMinMaxByLevel = async (levelId, filter = "daily") => {
    try {
        let response = await axios.get('levels/' + levelId + '/atmosphere/AQI/stats?filter=' + filter);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return { error: true };
    }
}

export let getAqiByDevice = async (deviceId, type, filter = "hourly") => {
    try {
        let response = await axios.get('devices/' + deviceId + '/atmosphere/stats?type=' + type + '&filter=' + filter);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return { error: true };
    }
}
export let getAqiWithMedianByCity = async (cityName, filter = "hourly", type = null) => {
    try {
        let response = await axios.get('levels/' + cityName + '/atmosphere/median?filter=' + filter + (type? '&type='+type:''));
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return { error: true };
    }
}

export let getAqiMinMaxByDevice = async (deviceId, filter = "daily") => {
    try {
        let response = await axios.get('devices/' + deviceId + '/atmosphere/AQI/stats?filter=' + filter);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        return { error: true };
    }
}

export let getEventsByLevel = async (levelId) => {
    try {
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/levels/' + levelId + '/events');
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        /*  return err.response.data; */
        return 'Errror'
    }
}
export let getMapDevice = async (levelId,type = null) => {
    try {
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let API = '/levels/' + levelId + '/devices/stats'
        // if(type){
        //     API += '?type='+type
        // }
        let response = await axios.get(API);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        /*  return err.response.data; */
        return 'Errror'
    }
}
export let getMapDeviceIBM = async (levelId,type = null) => {
    try {
        let token = cookies.getCookie('token');
        axiosIBM.defaults.headers['Authorization'] = 'Bearer ' + token;
        let API = '/levels/' + levelId + '/devices/stats'
        // if(type){
        //     API += '?type='+type
        // }
        let response = await axiosIBM.get(API);
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        /*  return err.response.data; */
        return 'Errror'
    }
}
export let getEventsByDeviceList = async (
    deviceIds,
    types,
    // limit,
    startDate,
    endDate
  ) => {
    try {
      let token = cookies.getCookie("token");
      axios.defaults.headers["Authorization"] = "Bearer " + token;
      let response;
      if (startDate === null && endDate === null) {
        response = await axios.get(
          "/devices/events/compare?devices=" +
            deviceIds +
            "&types=" +
            types 
            // "&limit=" +
            // limit
        );
      } else {
        response = await axios.get(
          "/devices/events/compare?devices=" +
            deviceIds +
            "&types=" +
            types +
            // "&limit=" +
            // limit +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
      }
      if (typeof response.data == "string") {
        return await dataHandler(response.data);
      } else {
        return response.data;
      }
    } catch (err) {
      if (err.response) {
        err.response.data.error = true;
        return err.response.data;
      } else {
        let error = {
          response: {
            data: {
              error: true,
              statusCode: 503,
              message: "Network Error",
            },
          },
        };
        return error.response.data;
      }
    }
  };
export let getEventsByDeviceListIBM = async (
    deviceIds,
    types,
    // limit,
    startDate,
    endDate
  ) => {
    try {
      let token = cookies.getCookie("token");
      axiosIBM.defaults.headers["Authorization"] = "Bearer " + token;
      let response;
      if (startDate === null && endDate === null) {
        response = await axiosIBM.get(
          "/devices/events/compare?devices=" +
            deviceIds +
            "&types=" +
            types 
            // "&limit=" +
            // limit
        );
      } else {
        response = await axiosIBM.get(
          "/devices/events/compare?devices=" +
            deviceIds +
            "&types=" +
            types +
            // "&limit=" +
            // limit +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate
        );
      }
      if (typeof response.data == "string") {
        return await dataHandler(response.data);
      } else {
        return response.data;
      }
    } catch (err) {
      if (err.response) {
        err.response.data.error = true;
        return err.response.data;
      } else {
        let error = {
          response: {
            data: {
              error: true,
              statusCode: 503,
              message: "Network Error",
            },
          },
        };
        return error.response.data;
      }
    }
  };
  