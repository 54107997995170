import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  HashRouter,
  Redirect,
} from "react-router-dom";
import AirQuality from "./containers/aq-container";
import Barrage from "./containers/bg-container";
import Weather from "./containers/weather-container";
import Energy from "./containers/energy-container";
import Home from "./components/home";
// import { getdata } from './getAlerts';
//import './scss/style.css'
import store from "./store";
import withClearCache from "./ClearCache";

class MainApp extends Component {
  componentDidMount() {
    store.dispatch({
      type: "CONNECT",
    });

    // getdata();
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          {/* <Route exact path="/signup" name="Signup Page" component={SignupContainer} /> */}
          {/* <Route
            path="/airquality/:location"
            name="AirQuality"
            component={AirQuality}
          /> */}
          <Route
            exact
            path="/"
            name="AirQuality"
            component={AirQuality}
          />
          <Route
            exact
            path="/temperature"
            name="Temperature"
            component={AirQuality}
          />
          <Route
            exact
            path="/humidity"
            name="Humidity"
            component={AirQuality}
          />
          <Route exact path="/airquality/:location">
            <Redirect to="/" />
           </Route>
          <Route path="/barrage" name="Barrage" component={Barrage} />
          <Route path="/weather" name="Weather" component={Weather} />
          <Route path="/energy" name="Energy" component={Energy} />
          {/* {/* <Route path="/" name="Home" component={Home} /> */}
        </Switch>
      </HashRouter>
    );
  }
}
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export default App;
